export const displayAnnouncementModal = () => {
  return {
    type: "DISPLAY_ANNOUNCEMENT_MODAL"
  };
};

export const closeAnnouncementModal = () => {
  return {
    type: "CLOSE_ANNOUNCEMENT_MODAL"
  };
};